import * as React from "react"

import Layout from "../components/Layout"
import {
    BreadTypography,
    CategoryCard,
    getCategoryList,
    PageHeader,
} from "../components/partials"
import {
    Breadcrumbs,
    Container,
    Grid,
    withStyles,
} from "@material-ui/core"
import {CategoryStyles} from "../styles/CategoryStyles"
import {Model} from "../types/Types";

type Props = {
    classes: {
        image: string
        card: string
    }
}
const Categories = ({classes}: Props) => {
    const cat = getCategoryList()
    const categories = Object.entries(cat) as [string, Model[]][];

    const categoriesMapped = categories.map(([category, models]) => {
        const image = models[0]?.metadata.imageurl.url;
        return {category, image}
    });

    return (
        <Layout
            HeaderComponent={
                <PageHeader id={"categoriesHeader"} header="Our Products"/>
            }
            BreadComponent={
                <Breadcrumbs aria-label="breadcrumb">
                    <BreadTypography>Products</BreadTypography>
                </Breadcrumbs>
            }
            hasFeatured={true}
        >
            <Container style={{padding: 0, width: "100%"}}>
                <Grid
                    container
                    style={{paddingTop: "10px", width: "auto"}}
                    spacing={1}
                    justify={"center"}
                    xs={12}
                    md={12}
                >
                    {categoriesMapped.map(category => (
                        <Grid item container justify="center" style={{width: "auto"}}>
                            <CategoryCard category={category}/>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Layout>
    )
}

export default withStyles(CategoryStyles)(Categories)
